<template>
  <div class="modal fade" id="reportedHoursModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reportedHoursModalLabel">
            Change Report
          </h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-5 pt-3">
            <div class="col-4">
              <label class="required fs-6 fw-bold mb-2">Start time</label>
              <div class="start">
                <input
                  v-model="inputs.startTime"
                  id="startHour"
                  class="form-control mb-1 me-2 w-75"
                />
                <span class="text-muted" v-if="modalData !== null">{{
                  modalData.start
                }}</span>
              </div>
            </div>
            <div class="col-4">
              <label class="required fs-6 fw-bold mb-2">End time</label>
              <div class="start">
                <input
                  v-model="inputs.endTime"
                  id="finishHour"
                  class="form-control me-2 w-75"
                />
                <span class="text-muted" v-if="modalData !== null">{{
                  modalData.end
                }}</span>
              </div>
            </div>
            <div class="col-4 d-flex align-items-center">
              <el-checkbox v-model="inputs.iwasLate">Was late</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="fs-6 fw-bold mb-2">Comment:</label>
              <div
                class="col-12
        "
              >
                <el-input
                  class="w-100"
                  placeholder="Enter comment..."
                  v-model="inputs.comment"
                  :disabled="true"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="$emit('delete')"
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Delete
          </button>
          <button
            @click="
              $emit('update', { inputs: inputs, reportedHour: reportedHours })
            "
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Update
          </button>
          <button
            @click="$emit('approve', modalData)"
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Inputmask from "inputmask";

function initialData() {
  return {
    startTime: "",
    endTime: "",
    iwasLate: 0,
    comment: ""
  };
}
export default defineComponent({
  name: "reported-hours-modal",
  props: {
    modalData: {
      type: Object || null,
      required: false
    },
    reportedHours: {
      type: Object || Array || null,
      required: false
    }
  },
  data: () => ({
    inputs: initialData()
  }),
  watch: {
    modalData: {
      deep: true,
      handler() {
        if (this.reportedHours) {
          this.inputs.startTime = this.reportedHours.start.substring(11);
          this.inputs.endTime = this.reportedHours.end.substring(11);
          this.inputs.iwasLate = this.reportedHours.i_was_late == 1 ? true : false;
          this.inputs.comment = this.reportedHours.comment;
        } else {
          this.inputs = initialData();
        }
      }
    }
  },
  mounted() {
    Inputmask({
      regex: "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask(`#startHour`);

    Inputmask({
      regex: "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask(`#finishHour`);
  }
});
</script>
