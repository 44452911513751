
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "total-approved-hours-widget",
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item"
    })
  },
  methods: {
    totalApproved(index) {
      const approved = this.job.shifts[index].shift_slots
        .map(slot => slot.applications)
        .flat()
        .filter(
          application =>
            application.status == "Approved" || application.status == "Paid"
        )
        .map(application => application.assignee_id);
      if (approved.length > 0) {
        const assignees = approved.filter(function(item, pos) {
          return approved.indexOf(item) == pos;
        });
        const reportedHours = this.job.shifts[index].shift_slots
          .map(slot => slot.reported_hours)
          .flat()
          .filter(hour => assignees.includes(hour.created_by_id))
          .map(hour => hour.duration);
        if (reportedHours.length > 0) {
          const duration = reportedHours.reduce((a, b) => a + b);
          if (Number.isInteger(duration)) return duration + "hrs";
          else return this.calculateMinutes(duration);
        }
      } else {
        return 0 + "hrs";
      }
    },
    calculateMinutes(data) {
      const integer = Math.floor(data);
      const float = data - integer;
      const minutes = Math.round(60 * float);
      return `${integer}hrs ${minutes}min`;
    }
  }
});
