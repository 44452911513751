import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6 mb-3" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "svg-icon svg-icon-3 svg-icon-success me-2" }
const _hoisted_4 = { class: "fs-2 fw-bolder" }
const _hoisted_5 = { class: "fw-bold fs-6 text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_inline_svg, { src: "media/icons/duotone/General/User.svg" })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.totalWorkers(_ctx.index)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.totalWorkers(_ctx.index) > 1 ? "Workers" : "Worker"), 1)
  ]))
}