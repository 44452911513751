<template>
  <div v-if="job">
    <ReportHoursModal
      :modalData="selectedForReport"
      :key="key"
      @report="handleReport"
    ></ReportHoursModal>
    <ReportedHoursModal
      :modalData="selectedForUpdate"
      :reportedHours="reportedHours"
      @approve="handleApproveHours"
      @delete="handleDeleteReport"
      @update="handleUpdateReport"
    ></ReportedHoursModal>
    <ApprovedHoursModal
      :modalData="currentlyViewedReport"
      @disapprove="handleDissaprove"
    ></ApprovedHoursModal>
    <div
      v-for="(shift, index) in filterShiftsIfWorker"
      :key="shift.id"
      :ref="`${shiftIsActive(index, shift)}`"
    >
      <div class="card card-flush mt-6 mt-xl-9 accordion accordion-item">
        <div
          class="m-3 accordion-header"
          data-bs-toggle="collapse"
          :data-bs-target="'#job_detail_accordion_' + index"
          aria-expanded="true"
          :aria-controls="'job_detail_accordion_' + index"
        >
          <div class="row">
            <div class="col min-w-175px">
              <div class="position-relative ps-6 pe-3 py-2">
                <div
                  class="position-absolute start-0 top-0 w-4px h-100 rounded-2"
                  :class="`${getStatusClass(shift.crew)}`"
                ></div>
                <a
                  href="#"
                  class="mb-1 text-gray-900 text-hover-primary fw-bold"
                  :class="
                    shift.status == 'Cancelled'
                      ? 'text-decoration-line-through'
                      : ''
                  "
                  >Shift #{{ index + 1 }}</a
                >
                <div class="fs-7 text-muted fw-bold">{{ shift.label }}</div>
              </div>
            </div>
            <div class="col d-flex align-items-center">
              <div class="d-flex flex-wrap gap-2">
                <BookedCrewPill
                  class="me-1"
                  v-for="(crew, index) in shift.crew.breakdown"
                  :data="crew.label"
                  :percentage="crew.progress"
                  :key="index"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          :class="`accordion-collapse collapse ${shiftIsActive(index, shift)}`"
          :id="'job_detail_accordion_' + index"
          aria-labelledby="kt_accordion_1_header_1"
          :data-bs-parent="'#job_detail_accordion_' + index"
        >
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12 col-md-6">
                <div
                  class="d-flex flex-wrap"
                  v-if="$can('can_view_shifts_widgets', 'all')"
                >
                  <TotalWorkersWidget :index="index"></TotalWorkersWidget>
                  <TotalShiftTimeWidget :index="index"></TotalShiftTimeWidget>
                  <TotalReportedTimeWidget
                    :index="index"
                  ></TotalReportedTimeWidget>
                  <TotalApprovedTimeWidget
                    :index="index"
                  ></TotalApprovedTimeWidget>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                  <div
                    v-if="
                      shift.dresscode &&
                        !shift.is_past &&
                        $can('can_see_shift_dresscode', 'all')
                    "
                    class="alert alert-dismissible bg-light-info border border-info border-3 border-dashed d-flex flex-column flex-sm-row p-5 mb-5"
                  >
                    <i class="las la-3x la-hard-hat me-4 mb-5 mb-sm-0"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></i>
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                      <h5 class="mb-1">Dress Code</h5>
                      <span>{{ shift.dresscode.name }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      shift.notes_for_workers &&
                        !shift.is_past &&
                        $can('sees_worker_notes', 'all')
                    "
                    class="alert alert-dismissible bg-light-warning border border-warning border-3 border-dashed d-flex flex-column flex-sm-row p-5"
                  >
                    <i class="las la-3x la-sticky-note me-4 mb-5 mb-sm-0"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></i>
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                      <h5 class="mb-1">Worker notes</h5>
                      <span>{{ shift.notes_for_workers }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive overflow-control">
              <ShiftSlotListing
                :key="shiftSlotKey"
                @approve="handleApproveHours"
                @openReportModal="handleReportModal"
                @openUpdateReportModal="handleUpdateReportModal"
                @viewReport="handleViewReportModal"
                :shift="shift"
              >
              </ShiftSlotListing>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import ShiftSlotListing from "@/components/jobs/ShiftSlotListing.vue";
import ReportHoursModal from "../ReportHours/ReportHoursModal.vue";
import ReportedHoursModal from "../ReportHours/ReportedHoursModal.vue";
import ApprovedHoursModal from "../ReportHours/ApprovedHoursModal.vue";
import TotalWorkersWidget from '../Widgets/TotalWorkers.vue';
import TotalShiftTimeWidget from '../Widgets/TotalShiftTime.vue';
import TotalReportedTimeWidget from '../Widgets/TotalReported.vue';
import TotalApprovedTimeWidget from '../Widgets/TotalApproved.vue';
import {Collapse} from "bootstrap";
import BookedCrewPill from "@/components/general/BookedCrewPill.vue";

export default defineComponent({
  name: "job-shift-card",
  components: {
    BookedCrewPill,
    TotalWorkersWidget,
    TotalShiftTimeWidget,
    TotalReportedTimeWidget,
    TotalApprovedTimeWidget,
    ApprovedHoursModal,
    ReportedHoursModal,
    ReportHoursModal,
    ShiftSlotListing
  },
   computed: {
    ...mapGetters("JobModule", {
      job: "item",
      allShiftsAreExpanded: "allShiftsAreExpanded"
    }),
    filterShiftsIfWorker() {
      if(!this.$can("shifts_view_others", 'all')) return this.job.shifts.filter(a => a.my_shift_slot !== null)
      else return this.job.shifts
    },
  },
  data() {
    return{
      allReportedHours: [],
      selectedForReport: null,
      selectedForUpdate: null,
      currentlyViewedReport: null,
      reportedHours: null,
      shiftSlotKey: 0,
      key: 0
    }
  },
  mounted() {
    if (this.$route.query.shift !== undefined && this.$refs.show) {
      if(this.$refs.show && this.$refs.show[0]) {
        this.$smoothScroll({
          scrollTo: this.$refs.show[0],
          duration: 1500,
          updateHistory: false
        })
      }
    }
  },
  watch: {
    job: {
      deep:true,
      handler() {
        this.shiftSlotKey++;
      }
    },
    allShiftsAreExpanded() {
      for (let i = 0; i < this.filterShiftsIfWorker.length; i++) {
        const shiftToToggle = document.getElementById(`job_detail_accordion_${i}`);
        const collapse = new Collapse(shiftToToggle, {
          toggle: ((shiftToToggle.classList.contains('show') && this.allShiftsAreExpanded) ||
                  (!shiftToToggle.classList.contains('show') && !this.allShiftsAreExpanded)) ? false : true
        });
      }
    }
  },
  methods: {
    ...mapActions("JobModule", [
      "reportHours",
      "approveHours",
      "updateHours",
      "disapproveReport",
      "updateReportedHours",
      "deleteReportedHours"
    ]),
    handleReportModal(data) {
      this.selectedForReport = data;
    },
    handleDissaprove(data) {
      this.disapproveReport(data);
    },
    handleUpdateReport(data) {
      this.updateReportedHours({...data, application: this.selectedForUpdate});
    },
    handleDeleteReport() {
      this.deleteReportedHours(this.selectedForUpdate);
    },
    handleUpdateReportModal(data) {
      this.selectedForUpdate = data;
      this.reportedHours = this.getRelatedReportedHours(data);
    },
    handleApproveHours(data) {
      this.approveHours(data);
    },
    getRelatedReportedHours(data) {
      const reportedHours = this.job.shifts.map(shift => shift.shift_slots).flat().find(shiftSlot => shiftSlot.id == data.shiftSlotId).reported_hours;
      reportedHours.filter(hour => hour.shift_slot_id !== data.shiftSlotId)
      return reportedHours.find(reportedHour => reportedHour.created_by_id == data.assignee_id);
    },
    handleViewReportModal(payload) {
      const data = this.getRelatedReportedHours(payload);
      if(data) {
        this.currentlyViewedReport = {reportedHour: data, application: payload};
      }else {
        this.currentlyViewedReport = {reportedHour: null, application: payload};;
      }
    },
    handleReport(dataFromModal) {
      this.reportHours({ reportedHours: dataFromModal, data: this.selectedForReport });
      this.key++;
    },
    reportedTime(shift) {
      if(shift.shift_slots.map(a => a.reported_hours).flat().length > 0) {
        return shift.shift_slots.map(a => a.reported_hours).flat().map(a => a.duration).reduce((a, b) => a + b);
      }else return 0;
    },
    // The idea here is that there should always be at least one expanded shift card based on the following conditions:
    // 1. If a user is to visit /jobs/:id/shifts on a job that has upcoming shifts, the closest shift card should be expanded.
    // 2. If the user visits /jobs/:id/shifts on a job where all shifts are past one, the latest passed shift card should be expanded.
    // 3. If the user clicks on a specific shift - no matter upcoming or past, a smooth scroll animation will be performed, navigating
    // the user to the expanded card of the selected shift.
    shiftIsActive(index, shift) {
      const query = this.$route.query;
      if ((query.shift !== undefined && query.shift == shift.id) ||
          (query.shift == undefined && this.job.closes_shift_time == shift.label && !shift.is_past) ||
          (query.shift == undefined && index == (this.job.shifts.length - 1) && shift.is_past)) {
        return 'show';
      } else {
        return '';
      }
    },
    getStatusClass(breakdown) {
      const total = breakdown.total;
      const totalBooked = breakdown.total_booked;

      const percentageOfCrewBooked = Math.floor((totalBooked / total) * 100);
      if(percentageOfCrewBooked <= 33) {
        return "bg-danger"
      } else if(percentageOfCrewBooked > 33 && percentageOfCrewBooked < 99){
        return "bg-warning"
      }
      return "bg-success"
    }
  }
});
</script>

<style scoped>
@media all and (min-width: 767px) {
  .overflow-control {
    overflow-x: visible;
  }
}
</style>
