<template>
  <div class="modal fade" id="approvedHoursModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="approvedHoursModalLabel">
            View Report
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body" v-if="modalData !== null">
          <div v-if="modalData.reportedHour == null">
            No approved hours data
          </div>
          <div class="row mb-5 pt-3" v-else>
            <div class="col-4">
              <!--begin::Label-->
              <label class="required fs-6 fw-bold mb-2">Start time</label>
              <!--end::Label-->
              <div class="start">
                <input
                  class="form-control mb-1 me-2 w-75"
                  :value="modalData.reportedHour.start.substring(11)"
                  disabled="true"
                />
                <span class="text-muted">{{
                  modalData.reportedHour.start
                }}</span>
              </div>
            </div>
            <div class="col-4">
              <!--begin::Label-->
              <label class="required fs-6 fw-bold mb-2">End time</label>
              <!--end::Label-->
              <div class="start">
                <input
                  class="form-control me-2 w-75"
                  :value="modalData.reportedHour.end.substring(11)"
                  disabled="true"
                />
                <span class="text-muted">{{ modalData.reportedHour.end }}</span>
              </div>
            </div>
            <div class="col-4 d-flex align-items-center">
              <el-checkbox :checked="modalData.reportedHour.i_was_late == 1 ? true : false" disabled='true'>I was late</el-checkbox>
            </div>
          </div>
          <div class="row" v-if="modalData.reportedHour !== null">
            <div class="col-12">
              <label class="fs-6 fw-bold mb-2">Comment:</label>
              <div
                class="col-12
        "
              >
                <el-input
                  class="w-100"
                  placeholder="Enter comment..."
                  :disabled="true"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="$emit('disapprove', modalData)"
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Disapprove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "approved-hours-modal",
  props: {
    modalData: {
      type: Object,
      required: false
    }
  }
});
</script>
