
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "total-workers-widget",
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item"
    })
  },
  methods: {
    totalWorkers(index) {
      return this.job.shifts[index].crew.total;
    }
  }
});
