
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "total-reported-hours-widget",
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item"
    })
  },
  methods: {
    totalReported() {
      const reportedHours = this.job.shifts[this.index].shift_slots
        .map(slot => slot.reported_hours)
        .flat()
        .map(hour => hour.duration);
      if (reportedHours.length > 0) {
        const hour = reportedHours.reduce((a, b) => a + b);
        if (Number.isInteger(hour)) return hour + "hrs";
        else return this.calculateMinutes(hour);
      } else {
        return 0 + "hrs";
      }
    },
    calculateMinutes(data) {
      const integer = Math.floor(data);
      const float = data - integer;
      const minutes = Math.round(60 * float);
      return `${integer}hrs ${minutes}min`;
    }
  }
});
