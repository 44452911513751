<template>
  <div class="modal fade" id="reportHoursModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reportHoursModalLabel" v-if="modalData !== null">{{ modalData.data.assignee.name }} - {{ modalData.start }} </h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-5 pt-3">
            <div class="col-4">
              <label class="required fs-6 fw-bold mb-2">Start time</label>
              <div class="start">
                <input
                  v-model="inputs.startTime"
                  id="startHour"
                  class="form-control mb-1 me-2 w-75"
                />
                <span class="text-muted" v-if="modalData !== null">{{ modalData.start }}</span>
              </div>
            </div>
            <div class="col-4">
              <label class="required fs-6 fw-bold mb-2">End time</label>
              <div class="start">
                <input
                  v-model="inputs.endTime"
                  id="finishHour"
                  class="form-control me-2 w-75"
                />
                <span class="text-muted" v-if="modalData !== null">{{ handleEndLabel }}</span>
              </div>
            </div>
            <div class="col-4 d-flex align-items-center">
              <el-checkbox v-model="inputs.iwasLate">I was late</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="fs-6 fw-bold mb-2">Comment:</label>
              <div
                class="col-12
        "
              >
                <el-input
                  class="w-100"
                  placeholder="Enter comment..."
                  v-model="inputs.comment"
                  :disabled="true"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="$emit('report', inputs)"
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
          >
            Report
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Inputmask from "inputmask";
import moment from "moment";

function initialData() {
  return {
    startTime: "",
    endTime: "",
    iwasLate: 0,
    comment: ""
  };
}
export default defineComponent({
  name: "report-hours-modal",
  props: {
    modalData: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    inputs: initialData()
  }),
  watch: {
    modalData: {
      deep: true,
      handler() {
        this.inputs = initialData();
        this.populateInputs();
      }
    }
  },
  methods: {
    populateInputs() {
      this.inputs.startTime = this.modalData.start.substring(11);
      this.inputs.endTime = this.modalData.end.substring(11);
    },
  },
  computed: {
    handleEndLabel() {
      if(this.modalData && this.modalData.end) {
        const inputEnd =
            moment(`${this.modalData.start.substring(0,10)} ${this.inputs.endTime}`, "DD-MM-YYYY HH:mm");
        if(inputEnd.valueOf() < moment(this.modalData.start, "DD-MM-YYYY HH:mm")) {
          return inputEnd.add(1, 'day').format("DD/MM/YYYY HH:mm")
        }
        return inputEnd.format("DD/MM/YYYY HH:mm");
      }
      return '';
    }
  },
  mounted() {
    Inputmask({
      regex: "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask(`#startHour`);

    Inputmask({
      regex: "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"
    }).mask(`#finishHour`);
  }
 });
</script>
