<template>
  <table
    id="kt_profile_overview_table"
    class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder mb-0"
  >
    <thead class="fs-7 text-gray-400 text-uppercase">
      <tr>
        <th class="min-w-250px">Worker</th>
        <th v-if="$can('manage_crew_bosses', 'all')">Crew Boss</th>
        <th
          class="min-w-150 min-w-md-90px"
          v-if="
            $can('view_shifts_contact', 'all') ||
              $can('can_view_contact_details_in_job_view', 'all')
          "
        >
          Contact
        </th>
        <th
          class="min-w-90px"
          :class="
            !$can('report_hours_for_other_users', 'all') || shift.is_past
              ? 'text-end'
              : ''
          "
        >
          Status
        </th>
        <th
          class="min-w-50px text-end"
          v-if="$can('report_hours_for_other_users', 'all') && shift.is_past"
        >
          Work Log
        </th>
      </tr>
    </thead>
    <tbody class="fs-6">
      <tr v-for="(slot, index) in properShiftSlots" :key="index">
        <td>
          <div class="d-flex align-items-center">
            <div class="me-5 position-relative">
              <div
                class="symbol symbol-35px symbol-circle justify-content-center"
              >
                <Img
                  alt="Pic"
                  :src="slot.assignee.photo_url"
                  v-if="slot.assignee"
                />
                <span class="svg-icon svg-icon-primary svg-icon-2x ms-1" v-else
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                ></span>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <div class="d-flex align-items-center" v-if="slot.assignee">
                <router-link
                  :to="`/users/${slot.assignee.id}/personal`"
                  class="fs-6 text-gray-800 text-hover-primary"
                  >{{ slot.assignee.name }}</router-link
                >
                <el-tooltip
                  content="The user has checked in for this shift"
                  placement="top"
                  v-if="
                    slot.check_in_at &&
                      $can('can_view_checked_in_status', 'all')
                  "
                >
                  <div
                    class="rounded-circle bg-light-success ms-2 cursor-pointer"
                  >
                    <span class="svg-icon svg-icon-success svg-icon-1">
                      <icon-check />
                    </span>
                  </div>
                </el-tooltip>
              </div>
              <span v-else
                >{{ slot.freeSlots }}
                {{ slot.freeSlots == 1 ? " Free slot" : " Free slots" }}</span
              >
              <div class="d-flex flex-wrap gap-3">
                <span class="badge badge-primary fit-content">{{
                  slot.skill
                }}</span>
                <span
                  v-if="
                    slot.user_level &&
                      getStatusName(slot) === 'Applied' &&
                      $can('can_view_applied_users_skill_level', 'all')
                  "
                  class="badge badge-secondary fit-content"
                  >{{ slot.user_level }}</span
                >
              </div>
            </div>
          </div>
        </td>
        <td v-if="$can('manage_crew_bosses')">
          <btn
            v-if="slot.assignee && canShowCrewBossButton(slot)"
            class="btn btn-sm"
            @click="handleCrewBossButton(slot)"
          >
            <i
              class="ki-duotone ki-crown fs-2x"
              :class="{ 'text-warning': isCrewBoss(slot, shift) }"
            >
              <i class="path1"></i>
              <i class="path2"></i>
            </i>
          </btn>
        </td>
        <td v-else-if="hasCrewBoss && slot.assignee">
          <i
            class="ki-duotone ki-crown fs-2x text-warning"
            v-if="isCrewBoss(slot, shift)"
          >
            <i class="path1"></i>
            <i class="path2"></i>
          </i>
        </td>
        <td
          v-if="
            $can('view_shifts_contact', 'all') ||
              $can('can_view_contact_details_in_job_view', 'all') ||
              (currentUser.company_id == item.company.id &&
                !shift.is_past &&
                $can('view_only_own_company_jobs', 'all'))
          "
        >
          <PhoneLink
            v-if="slot.assignee_id"
            :phone="slot.assignee.phone"
            css-classes="fs-6 text-gray-800 text-hover-primary"
          ></PhoneLink>
        </td>
        <td
          :class="
            !$can('report_hours_for_other_users', 'all') || shift.is_past
              ? 'text-end'
              : ''
          "
        >
          <ShiftSlotButton
            :status="slot.assignee ? getStatusName(slot) : 'Free'"
            :isShiftPast="shift.is_past"
            :shiftSlot="slot"
            @updatedStatus="updadeSlotStatus"
          ></ShiftSlotButton>
        </td>
        <td class="text-end" v-if="userCanReportForOthers(slot)">
          <button
            class="btn btn-danger btn-sm"
            @click="handleReport(slot)"
            data-bs-toggle="modal"
            data-bs-target="#reportHoursModal"
            v-if="
              getStatusName(slot) == 'Booked' ||
                getStatusName(slot) == 'Report!'
            "
            :disabled="isReportingHours.includes(slot.id) ? true : false"
          >
            <span
              v-if="isReportingHours.includes(slot.id)"
              class="spinner-border spinner-border-sm"
            ></span>
            Report hours
          </button>
          <div v-if="getStatusName(slot) == 'Reported'">
            <button
              class="btn btn-sm btn-warning"
              v-if="isDeletingReport.includes(slot.id)"
            >
              <span class="spinner-border spinner-border-sm me-3"></span
              >Deleting...
            </button>
            <button
              class="btn btn-sm btn-primary"
              v-if="isUpdatingHours.includes(slot.id)"
            >
              <span class="spinner-border spinner-border-sm me-3"></span
              >Updating...
            </button>
            <button
              class="btn btn-sm btn-success"
              v-if="isAwaitingApproval.includes(slot.id)"
            >
              <span class="spinner-border spinner-border-sm me-3"></span
              >Approving...
            </button>
            <div
              class="btn-group btn-group-sm"
              v-if="
                !isDeletingReport.includes(slot.id) &&
                  !isUpdatingHours.includes(slot.id) &&
                  !isAwaitingApproval.includes(slot.id)
              "
            >
              <button
                class="btn btn-sm btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#reportedHoursModal"
                @click="$emit('openUpdateReportModal', slot)"
              >
                Reported:
                {{
                  reportedHours({
                    id: slot.pivot.shift_slot_id,
                    assignee: slot.assignee.id
                  })
                }}
              </button>
              <button
                class="btn btn-sm btn-success"
                @click="$emit('approve', slot)"
              >
                <span
                  v-if="isAwaitingApproval.includes(slot.id)"
                  class="spinner-border spinner-border-sm"
                ></span>
                Approve
              </button>
            </div>
          </div>
          <div v-if="['Approved', 'Paid'].includes(getStatusName(slot))">
            <button
              class="btn btn-sm btn-warning "
              v-if="isAwaitingDisapproval.includes(slot.id)"
            >
              <span class="spinner-border spinner-border-sm me-3"></span
              >Disapproving
            </button>
            <button
              v-else
              class="btn btn-sm btn-success"
              data-bs-toggle="modal"
              data-bs-target="#approvedHoursModal"
              @click="$emit('viewReport', slot)"
            >
              Approved -
              {{
                reportedHours({
                  id: slot.pivot.shift_slot_id,
                  assignee: slot.assignee.id
                })
              }}
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="toggle-row text-center"
    @click="toggleHiddenSlots"
    style="cursor: pointer;"
    v-if="hiddenShiftSlots.length > 0"
  >
    {{ showHiddenSlots ? "Show Less" : "Show More" }}
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Img from "@/components/general/Img.vue";
import ShiftSlotButton from "@/components/jobs/ShiftSlotButton.vue";
import {BookedStatuses, PastShiftStatuses, VirtualShiftStatuses} from "@/core/helpers/shift_status.js";
import PhoneLink from "@/components/general/PhoneLink";
import moment from "moment";
import IconCheck from "@/components/Icons/IconCheck.vue";


export default defineComponent({
  name: "shift-slot-listing",
  props: { shift: Object },
  components: {
    IconCheck,
    ShiftSlotButton,
    Img,
    PhoneLink
  },
  data() {
    return {
      shiftSlots: [],
      showHiddenSlots: false,
    };
  },
  methods: {
    ...mapActions("JobModule", ["updateShiftSlot", "updateShiftCrew", 'addCrewBoss', 'removeCrewBoss']),
    getStatusName (a) {
      return a.status ? a.status.name : null;
    },
    formatDate(date) {
      if(date) {
        const momentObject = moment(date);
        if(momentObject.isValid()) {
          return momentObject.format('HH:MM DD/MM/YYYY')
        }
      }
      return "";
    },
    toggleHiddenSlots() {
      this.showHiddenSlots = !this.showHiddenSlots;
    },
    hasApprovedStatus() {
      if(this.item) {
        return ["Approved", "Invoiced", "Paid"].includes(this.item.status);
      }
      return false;
    },
    userCanReportForOthers(slot) {
      return (this.shift.is_past && slot.assignee) &&
        (this.$can('report_hours_for_other_users', 'all') ||
        (this.isCrewBossForThisShift(this.currentUser.id, this.shift) && !this.hasApprovedStatus()));
    },
    isCrewBossForThisShift(userId, shift) {
      return shift.crew_bosses.some(crewBoss => crewBoss.id === userId);
    },
    addSkillAndSlotIDToShiftSlot(slots, skill, shiftSlotId) {
      for(let i = 0 ; i < slots.length; i++) {            //Here we just loop through the slots to add a skill and shift slot id to them
        this.shiftSlots.push({                            //I put it in a standalone method for readability purposes
          ...slots[i],                                    //P.S Shift slot id is required when updating the status, its kinda
          skill : skill,                                  //bad to do it to every slot, but thats the most sure i found way to not
          shiftSlotId: shiftSlotId,
        });
      }
    },
    handleReport(slot) {
      this.$emit("openReportModal", {data: slot, start: this.shift.start, end: this.shift.end});
    },
    handleCrewBossButton(slot) {
      // Determine the current crew boss status for the slot.
      const currentIsCrewBoss = this.isCrewBoss(slot, this.shift);

      if (currentIsCrewBoss) {
        this.removeCrewBoss({shiftId: this.shift.id, userId: slot.assignee.id}).then(() => {
          // Update the local state to reflect the crew boss has been removed.
          const crewBossIndex = this.shift.crew_bosses.findIndex(crewBoss => crewBoss.id === slot.assignee.id);
          if (crewBossIndex !== -1) {
            // eslint-disable-next-line vue/no-mutating-props
            this.shift.crew_bosses.splice(crewBossIndex, 1);
          }
          this.updateLocalShiftSlotsAfterCrewBossChange(slot.assignee.id, false);
        });
      } else {
        this.addCrewBoss({shiftId: this.shift.id, userId: slot.assignee.id}).then(() => {
          // Update the local state to reflect the new crew boss.
          // eslint-disable-next-line vue/no-mutating-props
          this.shift.crew_bosses.push({id: slot.assignee.id}); // Assuming crewBoss object structure.
          this.updateLocalShiftSlotsAfterCrewBossChange(slot.assignee.id, true);
        });
      }
    },
    updateLocalShiftSlotsAfterCrewBossChange(userId, isCrewBoss) {
      this.shiftSlots = this.shiftSlots.map(slot => {
        // If this slot is associated with the user being updated, adjust its crew boss status.
        if (slot.assignee && slot.assignee.id === userId) {
          return {
            ...slot,
            isCrewBoss: isCrewBoss, // Directly update isCrewBoss status in the slot object.
          };
        }
        return slot;
      });
    },
    updadeSlotStatus(values) {
      const payload = {
        ...values,
        shiftID: this.shift.id
      }
      this.updateShiftSlot(payload);
      this.updateShiftCrew(this.shift.id);
      this.shiftSlots = [];
    },
    reportedHours(data) {
      const reportedHour = this.item.shifts.find(shift => shift.id == this.shift.id).shift_slots
          .find(slot => slot.id == data.id).reported_hours
          .find(reportedHour => reportedHour.created_by_id == data.assignee);
      if(reportedHour) {
        if(Number.isInteger(reportedHour.duration)) {
           return reportedHour.duration + 'hrs';
        } else {
           const integer = Math.floor(reportedHour.duration);
           const float = reportedHour.duration - integer;
           const minutes = Math.round(60 * float);
           return `${integer}hrs ${minutes}min`;
        }
      }
      else return "N/A"
    },
    canShowCrewBossButton(slot) {
      const allowedStatuses = ["Report!", "Booked", "Reported", "Approved", "Paid"];
      const slotStatusName = this.getStatusName(slot);
      return allowedStatuses.includes(slotStatusName);
    },
    sortShiftSlots(slots) {
      const priorityMap = {
        "Report!": 2,
        "Booked": 2,
        "Reported": 3,
        "Applied": 3,
        "Request accepted": 4,
        "Approved": 5,
        "Requested": 5,
        "Paid": 6,
        "Reserve": 6,
        "Denied": 8,
        "Request denied": 9
      };

      const getPriority = (slot) => {
        if (this.isCrewBoss(slot, this.shift)) return 1;
        if (slot.freeSlots) return 7;
        const status = this.getStatusName(slot);
        return priorityMap[status] || 10;
      };

      // Sort the passed array of slots and return the sorted array
      return slots.sort((a, b) => getPriority(a) - getPriority(b));
    },
    filterShiftSlotsByPermission(slots) {
      if(!this.$can("shifts_view_others", 'all') || this.$can("can_only_view_booked_level_slots", 'all')) {
        return slots.filter(a => this.getStatusName(a) === "Booked" || this.getStatusName(a) === "Reported" || this.getStatusName(a) === "Approved" || this.getStatusName(a) === "Report!" || a.freeSlots);
      }
      return slots;
    },
    isCrewBoss(slot, shift) {
      return slot.assignee && shift.crew_bosses.some(crewBoss => crewBoss.id === slot.assignee.id);
    },
    computeShiftSlots() {
      const allSlots = [];
      this.shift.shift_slots.forEach(slot => {
        const nonFreeSlots = slot.applications.filter(application =>
            application.status.name === "Booked" ||
            (this.shift.is_past ? PastShiftStatuses.includes(application.status.name) : BookedStatuses.includes(application.status.name))
        );

        const virtualSlots = slot.applications.filter(application => VirtualShiftStatuses.includes(application.status.name));

        // Process applications/slots that are not free (have an assignee or status)
        nonFreeSlots.forEach(application => {
          allSlots.push({
            ...application,
            skill: slot.skill.name, // Assuming `skill` is a property of `slot`
            shiftSlotId: slot.id,
          });
        });

        virtualSlots.forEach(application => {
          allSlots.push({
            ...application,
            skill: slot.skill.name, // Assuming `skill` is a property of `slot`
            shiftSlotId: slot.id,
          });
        });

        // Process free slots
        const freeSlots = slot.positions - nonFreeSlots.length;
        if (freeSlots > 0 && !this.shift.is_past) {
          allSlots.push({
            freeSlots: freeSlots,
            skill: slot.skill.name, // Assuming `skill` is a property of `slot`
            shiftSlotId: slot.id,
          });
        }
      });

      return this.filterShiftSlotsByPermission(allSlots);
    },
    isVisibleSlot(slot) {
      const status = this.getStatusName(slot);
      const isDenied = status === 'Denied';
      const isReserveAndFullyBooked = status === 'Reserve' && this.isShiftFullyBooked;
      const isAppliedAndFullyBooked = status === 'Applied' && this.isShiftFullyBooked;

      if (isDenied || isReserveAndFullyBooked || isAppliedAndFullyBooked) {
        return false;
      }

      return true;
    },
  },
  computed: {
    ...mapGetters({ currentUser : "currentUser"}),
    ...mapGetters("JobModule", [
      "isReportingHours",
      "isAwaitingApproval",
      "isDeletingReport",
      "isAwaitingDisapproval",
      "isUpdatingHours",
      "item"
    ]),
    hasCrewBoss() {
      return this.shift.crew_bosses.length > 0;
    },
    properShiftSlots() {
      if(this.showHiddenSlots) {
        return this.visibleShiftSlots.concat(this.hiddenShiftSlots);
      }

      return this.visibleShiftSlots;
    },
    visibleShiftSlots() {
      const allSlots = this.computeShiftSlots();
      const sortedSlots = this.sortShiftSlots(allSlots);
      return sortedSlots.filter(slot => this.isVisibleSlot(slot));
    },
    hiddenShiftSlots() {
      const allSlots = this.computeShiftSlots();
      const sortedSlots = this.sortShiftSlots(allSlots);
      return sortedSlots.filter(slot => !this.isVisibleSlot(slot));
    },
    isShiftFullyBooked() {
      const allSlots = this.computeShiftSlots();
      const sortedSlots = this.sortShiftSlots(allSlots);
      const freeSlots = sortedSlots.filter(slot => slot.freeSlots);
      return freeSlots.length === 0;
    },
  }
});
</script>

<style scoped>
.fit-content {
  width: fit-content;
}
.toggle-row {
  padding: 10px 0;
  color: #007bff; /* Bootstrap primary link color */
  font-weight: 500;
}

.toggle-row:hover {
  background-color: #f8f9fa; /* Light gray background */
}
</style>
