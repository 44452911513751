
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    data: {
      type: [String, null] as PropType<string | null>,
      default: () => null
    },
    percentage: {
      type: Number as PropType<number>,
      default: () => 0
    }
  },
  setup: function(props) {
    const className = computed<string>((): string => {
      if (props.percentage == 100) {
        return "badge-success";
      } else if (props.percentage > 30 && props.percentage < 90) {
        return "badge-warning";
      } else {
        return "badge-danger";
      }
    });
    return {
      className
    };
  }
});
