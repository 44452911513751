
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "total-shift-time-widget",
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters("JobModule", {
      job: "item"
    })
  },
  methods: {
    totalTime(index) {
      return (
        this.job.shifts[index].duration * this.job.shifts[index].crew.total
      );
    }
  }
});
