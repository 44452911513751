
import { defineComponent } from "vue";
import ShiftCard from "@/components/jobs/tabs/ShiftCard.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "job-shift",
  components: {
    ShiftCard
  },
  mounted() {
    setCurrentPageTitle("Shifts");
  }
});
