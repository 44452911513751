export const PastShiftStatuses = ["Report!", "Reported", "Approved", "Paid"];

export const BookedStatuses = [
  "Booked",
  "Report!",
  "Reported",
  "Approved",
  "Paid"
];

export const UpcomingShiftStatuses = [
  "Booked",
  "Requested",
  "Applied",
  "Reserve",
  "Denied",
  "Request accepted",
  "Request denied"
];

export const VirtualShiftStatuses = [
  "Applied",
  "Request accepted",
  "Request denied",
  "Denied",
  "Reserve"
];

export const MyDashboardPastShiftStatuses = [
  "Report!",
  "Reported",
  "Approved",
  "Denied"
];
