<template>
  <div v-if="$can('shift_slot_change_status', 'all')">
    <div
      class="btn-group dropdown w-75"
      data-boundary="scrollParent"
      v-if="status !== 'Free'"
    >
      <button
        type="button"
        :disabled="isAwaitingResponse"
        :class="buttonClass"
        class="btn btn-sm dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-boundary="scrollParent"
      >
        <span
          v-if="isAwaitingResponse"
          class="spinner-border spinner-border-sm"
        ></span>
        {{ shiftStatusIfPast }}
      </button>
      <ul class="dropdown-menu" data-boundary="scrollParent">
        <li v-for="type in possibleShiftTypes" :key="type">
          <span
            class="dropdown-item"
            role="button"
            @click="changeJobStatus(type)"
            >{{ type }}</span
          >
        </li>
      </ul>
    </div>
    <span
      class="badge badge-light fw-bolder px-4 py-3 w-75"
      :class="status == 'Free' ? 'badge-light' : 'badge-warning'"
      v-else
      >{{ shiftStatusIfPast }}</span
    >
  </div>
  <span class="btn btn-sm w-75 pe-none" :class="buttonClass" v-else>{{
    shiftStatusIfPast
  }}</span>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { PastShiftStatuses, UpcomingShiftStatuses } from "@/core/helpers/shift_status.js";
import { mapActions } from "vuex";

export default defineComponent({
  name: "shift-slot-button",
  props: {
    status: String,
    isShiftPast: Boolean,
    shiftSlot: Object
  },
  data() {
    return{
      isAwaitingResponse: false
    };
  },
  computed: {
    possibleShiftTypes() {
      if (this.isShiftPast) {
        return PastShiftStatuses.filter(job => job !== this.shiftStatusIfPast);
      } else {
        return UpcomingShiftStatuses.filter(job => job !== this.shiftStatusIfPast);
      }
    },
    buttonClass() {
      switch (this.shiftStatusIfPast) {
        case "Report!":
          return "btn-danger";
        case "Approved":
          return "btn-cyan";
        case "Reported":
          return "btn-success";
        case "Paid":
          return "btn-success";
        case "Denied":
          return "btn-danger";
        case "Applied":
          return "btn-gray"
        case "Booked":
          return "btn-success";
        case "Requested":
          return "btn-purple"
        case "Request accepted":
          return "btn-info"
        case "Request denied":
          return "btn-lightpurple"
      }
      return "btn-warning";
    },
    shiftStatusIfPast() {
      if (this.isShiftPast && this.status == "Booked") {
        return "Report!";
      } else {
        return this.status;
      }
    }
  },
  methods: {
    ...mapActions("JobModule", ["changeStatus"]),
    changeJobStatus(type) {
      this.isAwaitingResponse = true;
      this.changeStatus({
        ...this.shiftSlot,
        status: type
      }).then((response) => {
        this.isAwaitingResponse = false;
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.$emit("updatedStatus", {newStatus: response.status , id: this.shiftSlot.id, booked_by: response.booked_by, shiftSlotID: this.shiftSlot.shiftSlotId })
        }
      );
    },
  }
});
</script>

<style scoped>
.btn-cyan {
  background-color: #0dcaf0;
  color: white;
  border-color: #0dcaf0;
}
.btn-cyan:hover {
  background-color: #00d5ff;
}
.btn-gray {
  background-color: rgb(157, 156, 156);
  color: white;
}
.btn-purple {
  background-color: rgb(196, 16, 196);
  color: white;
}
.btn-lightpurple {
  background-color: #9370db;
  color: white;
}
</style>
